
import { defineComponent, onMounted, onUnmounted, reactive, ref, toRefs } from "vue";
import SigninService from "@/services/attendance/signin/SigninService";
import LocationHelper from "@/utils/LocationHelper";
import moment from "moment";
import Utils from "../../../../utils/Utils";
import { isPlatform } from "@ionic/core";
declare const BMap: any;

export default defineComponent({
  name: "PhoneSignIn",
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup(props) {
    const { userInfo } = toRefs(props);
    let user: any;
    if (userInfo != undefined && userInfo.value != undefined) {
      user = userInfo.value;
    }
    const detail = reactive({
      name: user.address,
      userName: user.name,
      orgName: user.orgName,
      photo: user.photoHttp,
      morningSignin: "09:00",
      morningSignout: "",
      afternoonSingnin: "",
      afternoonSingnout: "18:00",
    });
    const enablePMSign = ref(false);
    const date = ref("");
    const showSignInBtn = ref(true);
    const showSignOutBtn = ref(false);

    const signupInfo = reactive({
      morningSignin: "",
      morningSignout: "",
      morningStatus: 1,
      afternoonSignin: "",
      afternoonSignout: "",
      afternoonStatus: 1,
      notWorkDay: 0,
    });

    let morningSignin = ref("");
    let morningSignout = ref("");
    let afternoonSignin = ref("");
    let afternoonSignout = ref("");

    let signIn = ref("");
    let signOut = ref("");
    let julifmt = ref(0);

    let isRangeInner = ref(false);
    //0:正在获取当前位置 1:获取成功 2：超时仍未获取成功
    let statusGetLocation = ref(0);
    let latitude: number = 0;
    let longitude: number = 0;
    let currentAdress = ref("");
    let juli: number = -1;
    let placeList: Array<any> = new Array<any>();
    let placeName: string = "";
    let placelon: number = 0;
    let placelat: number = 0;
    let placeRange: number = 0;

    let isClick: boolean = true;

    const handleSign = (type: number) => {
      if (isClick) {
        if (longitude === 0 || latitude === 0) {
          Utils.presentToastWarning(
            "无法获取你的位置，请点击刷新重新获取或检查是否已开启定位权限"
          );
          return;
        }
        if (signupInfo.notWorkDay == 1) {
          //休息日直接提交
          submit(type);
          return;
        }
        //签退 如果在上班时间，确认是否要签退
        let thisTime = new Date();
        let thisDate =
          thisTime.getFullYear() +
          "/" +
          (thisTime.getMonth() + 1) +
          "/" +
          thisTime.getDate();
        let isConfirm = false;

        let morningSignin = new Date(thisDate + " " + detail.morningSignin + ":00");
        let afternoonSingnout = new Date(
          thisDate + " " + detail.afternoonSingnout + ":00"
        );
        if (!enablePMSign) {
          if (thisTime < afternoonSingnout && thisTime >= morningSignin) {
            isConfirm = true;
          }
        } else if (enablePMSign) {
          let morningSignout = new Date(thisDate + " " + detail.morningSignout + ":00");
          let afternoonSingnin = new Date(
            thisDate + " " + detail.afternoonSingnin + ":00"
          );
          if (thisTime < morningSignout && thisTime >= morningSignin) {
            isConfirm = true;
          } else if (thisTime > afternoonSingnin && thisTime < afternoonSingnout) {
            isConfirm = true;
          }
        }
        if (type == 1 && isConfirm) {
          let that = this;
          Utils.confirmDialog("提示", "当前时间处于上班时间，您确定是否要签退？", () => {
            submit(type);
          });
        } else {
          submit(type);
        }
      }
    };

    const service = new SigninService();

    const submit = (type: number) => {
      isClick = false;
      service
        .saveAttendance(user.id, type, longitude, latitude, currentAdress.value)
        .then(
          (res: any) => {
            if (type === 0) {
              const audio = <HTMLAudioElement>document.querySelector("#signinAudio");
              if (audio != null) {
                audio.play();
              }
            } else {
              const audio = <HTMLAudioElement>document.querySelector("#signoutAudio");
              if (audio != null) {
                audio.play();
              }
            }
            isClick = true;
            Utils.presentToastSuccess("操作成功");
            getList();
            /*  if (type === 1) {
              this.signOut = this.common.dateFormat(new Date, 'HH:mm');
            } else {
              this.signIn = this.common.dateFormat(new Date, 'HH:mm');
            }*/
          },
          (err: any) => {
            isClick = true;
            if (err.data.error != undefined) {
              Utils.presentToastWarning(err.data.error);
            }
          }
        )
        .catch((c: any) => {
          console.log(c);
        });
    };

    const getSignPlace = () => {
      service
        .getSignPlace(user.id)
        .then(
          (res: any) => {
            if (res) {
              for (let index = 0; index < res.length; index++) {
                const ele = res[index];
                placeList.push(ele);
                if (ele) {
                  placeName = ele.name;
                  placelon = ele.longitude;
                  placelat = ele.latitude;
                  placeRange = ele.signRange;
                  juli = 0;
                }
              }
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    /**
     * 今日打卡记录
     */
    const getList = () => {
      service
        .getAttList(user.id)
        .then(
          (res: any) => {
            if (res) {
              enablePMSign.value = res.isEnablePmSign == 1;
              signupInfo.morningSignin = res.morningSignin;
              signupInfo.morningStatus = res.morningStatus;

              if (enablePMSign.value) {
                //上午
                signIn.value = "";
                if (res.morningSignin) {
                  morningSignin.value = res.morningSignin;
                }
                if (res.morningSignout) {
                  morningSignout.value = res.morningSignout;
                }

                if (signIn.value == "" && res.morningStatusName) {
                  signIn.value = res.morningStatusName;
                }
                //下午
                signOut.value = "";
                if (res.afternoonSignin) {
                  afternoonSignin.value = res.afternoonSignin;
                }
                if (res.afternoonSignout) {
                  afternoonSignout.value = res.afternoonSignout;
                }
                // if (signOut.value == "" && res.afternoonStatusName) {
                //   signOut.value = res.afternoonStatusName;
                // }
              } else {
                if (res.morningSignin) {
                  signIn.value = res.morningSignin;
                } else if (res.morningStatusName) {
                  signIn.value = res.morningStatusName;
                }

                if (res.afternoonSignout) {
                  signOut.value = res.afternoonSignout;
                } else if (res.afternoonStatusName) {
                  signOut.value = res.afternoonStatusName;
                }
              }
              showSignBtn();
            } else {
              showSignBtn();
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    /**
     * 签到按钮显示情况
     */
    const showSignBtn = () => {
      //下午不打卡
      if (!enablePMSign.value) {
        if (isNullOrEmpty(signupInfo.morningSignin)) {
          showSignInBtn.value = true;
          showSignOutBtn.value = false;
        } else {
          showSignInBtn.value = false;
          showSignOutBtn.value = true;
        }
      }
      //下午打卡
      else if (enablePMSign.value) {
        let morningSigninTime = new Date(date.value + " " + detail.morningSignin);
        let morningSignoutTime = new Date(date.value + " " + detail.morningSignout);
        let afternoonSingninTime = new Date(date.value + " " + detail.afternoonSingnin);
        let afternoonSingnoutTime = new Date(date.value + " " + detail.afternoonSingnout);
        //时间在上午签退打卡之前
        let thisTime = new Date(date.value);
        if (thisTime <= morningSignoutTime) {
          //如果没有签到，则签到，否则签退
          if (isNullOrEmpty(signupInfo.morningSignin)) {
            showSignInBtn.value = true;
            showSignOutBtn.value = false;
          } else {
            showSignInBtn.value = false;
            showSignOutBtn.value = true;
          }
        }
        //时间在上午签退之后，下午签到之前
        else if (thisTime > morningSignoutTime && thisTime <= afternoonSingninTime) {
          //如果上午没有签到，则为下午签到
          if (isNullOrEmpty(signupInfo.morningSignin)) {
            showSignInBtn.value = false;
            showSignOutBtn.value = false;
          }
          //如果上午有签到
          else {
            //如果上午没有签退，则为上午签退
            if (isNullOrEmpty(signupInfo.morningSignout)) {
              showSignInBtn.value = false;
              showSignOutBtn.value = true;
            }
            //如果上午有签退，则为下午签到
            else {
              showSignInBtn.value = false;
              showSignOutBtn.value = false;
            }
          }
        }
        //下午签到之后
        else if (thisTime > afternoonSingninTime) {
          //如果没有下午签到
          if (isNullOrEmpty(signupInfo.afternoonSignin)) {
            showSignInBtn.value = true;
            showSignOutBtn.value = false;
          } else {
            showSignInBtn.value = false;
            showSignOutBtn.value = true;
          }
        }
      }
    };

    const isNullOrEmpty = (val: string) => {
      if (val == null || val == "") {
        return true;
      } else {
        return false;
      }
    };

    const getDetail = () => {
      service
        .getUserInfo(user.id)
        .then(
          (res: any) => {
            detail.name = res.name;
            detail.morningSignout = res.morningSignout;
            detail.afternoonSingnin = res.afternoonSingnin;
            detail.afternoonSingnout = res.afternoonSingnout;
            enablePMSign.value = res.isEnablePmSign == 1;
            getList();
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const calcWhichonePlace = (longitude: number, latitude: number) => {
      for (let i = 0; i < placeList.length; i++) {
        const e = placeList[i];
        let lon = e.longitude;
        let lat = e.latitude;
        let jl = LocationHelper.getDistance(longitude, latitude, lon, lat);
        if (juli == 0 || juli > jl) {
          juli = jl;
          placeName = e.name;
          placelon = e.longitude;
          placelat = e.latitude;
          placeRange = e.signRange;
        }
      }
    };

    const translateLonLat = (longitude: any, latitude: any) => {
      if (juli != -1 && placelon != 0 && placelat != 0) {
        calcWhichonePlace(longitude, latitude);
        isRangeInner.value = juli < placeRange;
      }
      if (isRangeInner.value) {
        statusGetLocation.value = 1;
        currentAdress.value = placeName;
        julifmt.value = parseInt(juli + "");
      } else {
        LocationHelper.makeLocationAddress(longitude, latitude);
        currentAdress.value = LocationHelper.locationAddress;
        statusGetLocation.value = 1;
      }
    };

    let attSignInterval: any = null;

    const startSignIntegerval = () => {
      attSignInterval = setInterval(() => {
        if (LocationHelper.longitude !== 0 && LocationHelper.latitude !== 0) {
          if (isPlatform("ios")) {
            let pointArr = [];
            pointArr.push(
              new BMap.Point(LocationHelper.longitude, LocationHelper.latitude)
            );
            let convertor = new BMap.Convertor();
            convertor.translate(pointArr, 1, 5, (data: any) => {
              if (data.status === 0) {
                longitude = data.points[0].lng;
                latitude = data.points[0].lat;
                translateLonLat(longitude, latitude);
              }
            });
          } else {
            longitude = LocationHelper.longitude;
            latitude = LocationHelper.latitude;
            translateLonLat(longitude, latitude);
          }
        }
      }, 2000);
    };

    const clearSignIntegerval = () => {
      if (attSignInterval != null) {
        clearInterval(attSignInterval);
      }
    };

    const initData = () => {
      getSignPlace();
      getDetail();
      startSignIntegerval();
    };

    onMounted(() => {
      date.value = moment(new Date()).format("YYYY-MM-DD");
      initData();
    });

    onUnmounted(() => {
      clearSignIntegerval();
    });

    return {
      detail,
      enablePMSign,
      date,
      showSignInBtn,
      showSignOutBtn,
      handleSign,
      isRangeInner,
      statusGetLocation,
      currentAdress,
      signupInfo,
      morningSignin,
      morningSignout,
      afternoonSignin,
      afternoonSignout,
      signIn,
      signOut,
      julifmt,
    };
  },
});
