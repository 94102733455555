
import { defineComponent, reactive, ref, onMounted, onUnmounted } from "vue";
import Header from "@/components/Header.vue";
import { useRouter } from "vue-router";
import AttendanceSignInPage from "@/views/attendance/signIn/SignInPage.vue";
import AttendanceApplyPage from "@/views/attendance/apply/ApplyPage.vue";
import AttendanceApprovalPage from "@/views/attendance/approval/ApprovalPage.vue";
import AttendanceStatisticsPage from "@/views/attendance/statistics/StatisticsPage.vue";
import AttendanceManagePage from "@/views/attendance/manage/ManagePage.vue";
import LocationHelper from "@/utils/LocationHelper";
import { PermissionService } from "@/services/app/permission/PermissionService";
import { LeaveTypeService } from "@/services/attendance/leavetype/LeaveTypeService";

export default defineComponent({
  name: "AttendancePage",
  components: {
    Header,
    AttendanceSignInPage,
    AttendanceApplyPage,
    AttendanceApprovalPage,
    AttendanceStatisticsPage,
    AttendanceManagePage,
  },
  setup() {
    const attTabs = reactive({
      title: ["打卡", "申请", "管理"],
      code: [
        "AttendanceSignInPage",
        "AttendanceApplyPage",
        "AttendanceManagePage",
      ],
    });
    const selTb = ref(0);
    const rightSize = ["38px"];
    const btnSizeRight = reactive({ data: rightSize });
    const btnIconRight = reactive({ data: ["iconleijigongshi"] });
    const isApproval = ref(false);
    const PermissionApi = new PermissionService();
    const checkChip = (st: any) => {
      selTb.value = st;
      if (st == 0 || st == 1) {
        btnSizeRight.data = rightSize;
        btnIconRight.data = ["iconleijigongshi"];
      } else if (st == 2 && attTabs.title[2] == "审批") {
        btnSizeRight.data = rightSize;
        btnIconRight.data = ["iconleijigongshi","iconbianjijieduan"];
      } else {
        btnSizeRight.data = new Array();
        btnIconRight.data = new Array();
      }
    };

    let unSelectPermissionList=ref("");
    const getApprovalPermission = () => {
      PermissionApi.unselect().then((res: any) => {
        let permissionApproval = res.filter((item: any) => {
          return item.moduleName == "考勤系统";
        });
        unSelectPermissionList.value=JSON.stringify(permissionApproval[0].lst);
        let Approval = permissionApproval[0].lst.filter((item: any) => {
          return item.name == "我的待办";
        });
        isShowApproval.value = Approval.length > 0 ? true : false;
        if (isShowApproval.value) {
          (attTabs.title = ["打卡", "申请", "审批", "统计", "管理"]),
            (attTabs.code = [
              "AttendanceSignInPage",
              "AttendanceApplyPage",
              "AttendanceApprovalPage",
              "AttendanceStatisticsPage",
              "AttendanceManagePage",
            ]);
        }
      });
    };

    const router = useRouter();

    let isApprovalEdit = ref(0);

    const approvalRef = ref();

    const openFilter = (r: any) => {
      if (r == "0_icon") {
        if (selTb.value == 0) {
          router.push({
            name: "FilterSignIn",
          });
        }
        if (selTb.value == 1) {
          router.push({
            name: "MyDonePage",
          });
        }
        if (selTb.value == 2) {
          router.push({
            name: "MyDonePage",
          });
        }
      }
      if (r == "1_icon") {
        if (approvalRef.value != undefined) {
          isApproval.value = !isApproval.value;
          approvalRef.value.isShowBtn();
          if (isApproval.value) {
            btnIconRight.data = ["iconleijigongshi", "icontouxiangshanchu"];
          } else {
            btnIconRight.data = ["iconleijigongshi", "iconbianjijieduan"];
          }
        }
      }
    };
    const getApprovalEdit = () => {
      isApproval.value = false;
      btnIconRight.data = ["iconleijigongshi", "iconbianjijieduan"];
    };
    const getTab = () => {
      return attTabs.code[selTb.value];
    };
    const isShowApproval = ref(false);
    onMounted(() => {
      getApprovalPermission();
    });

    onUnmounted(() => {
      LocationHelper.clearLocationInterval();
      LocationHelper.locationIntervalStatus = 0;
    });

    return {
      attTabs,
      selTb,
      btnSizeRight,
      btnIconRight,
      checkChip,
      openFilter,
      getTab,
      isApprovalEdit,
      approvalRef,
      getApprovalEdit,
      isShowApproval,
      unSelectPermissionList
    };
  },
});
