
import { defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";
import QrcodeSignIn from "@/views/attendance/signIn/qrcode/QrcodeSignIn.vue";
import OutSignIn from "@/views/attendance/signIn/out/OutSignIn.vue";
import PhoneSignIn from "@/views/attendance/signIn/phone/PhoneSignIn.vue";
import SigninService from "@/services/attendance/signin/SigninService";
import SystemUtils from "@/utils/SystemUtils";
import LocationHelper from "@/utils/LocationHelper";

export default defineComponent({
  name: "AttendanceSignInPage",
  components: {
    QrcodeSignIn,
    OutSignIn,
    PhoneSignIn,
  },
  setup() {
    let checkIndex = ref(0);

    const chips = reactive({
      data: ["二维码", "外出签到", "APP签到"],
      code: ["QrcodeSignIn", "OutSignIn", "PhoneSignIn"],
    });

    const checkChipSign = (i: number) => {
      console.log(i);
      
      checkIndex.value = i;
    };

    const getSignIn = () => {
      return chips.code[checkIndex.value];
    };

    const user = SystemUtils.loginUser;
    const service = new SigninService();

    const userInfo = ref({});
    const loadUserInfo = () => {
      service
        .getUserInfoById(user.id, "")
        .then(
          (r: any) => {
            if (r) {
              userInfo.value = r;
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    loadUserInfo();

    onMounted(() => {
      LocationHelper.locationIntervalStatus = 1;
      LocationHelper.startLocationInterval();
    });

    onUnmounted(() => {
      LocationHelper.locationIntervalStatus = 1;
      LocationHelper.clearLocationInterval();
      LocationHelper.locationIntervalStatus = 0;
    });

    return {
      checkIndex,
      checkChipSign,
      chips,
      getSignIn,
      userInfo,
    };
  },
});
