
import { defineComponent, reactive, ref, onMounted, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import Utils from "@/utils/Utils";
import ApprovalCard from "@/components/ListCard/approvalCard.vue";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import regExps from "@/utils/regExps";
export default defineComponent({
  name: "AttendanceApprovalPage",
  components: {
    ApprovalCard,
  },
  emits: ["on-edit"],

  watch: {
    $route(to, from) {
      if (to.path == "/attendance") {
        this.resetPage();
      }
    },
  },
  setup(props, context) {
    const api = new WorkflowService();
    const router = useRouter();
    const checkIndex = ref(0);
    let showNoDate = ref(false);
    const chips = reactive({
      data: [
        { name: "全部", id: 0 },
        { name: "请假", id: 1 },
        { name: "出差", id: 2 },
        { name: "外出", id: 3 },
        { name: "异常打卡", id: 4 },
        { name: "驻场", id: 5 },
        { name: "销假", id: 6 },
        { name: "补假", id: 199 },
      ],
    });
    const searchParams = reactive({
      page: 1,
      size: 10,
    });

    const checkChip = (i: number) => {
      checkIndex.value = i;
      resetPage();
    };

    const approvalInfo = (item: any) => {
      let handleType = 0;
      const path = ref();
      if (item.workflowType === 1) {
        path.value = "/LeaveInfo";
      } else if (item.workflowType === 2) {
        path.value = "/TravelInfo";
      } else if (item.workflowType === 3) {
        path.value = "/OndutyInfo";
      } else if (item.workflowType === 4) {
        path.value = "/AbnormalInfo";
      } else if (item.workflowType === 5) {
        path.value = "/StationInfo";
      } else if (item.workflowType === 6) {
        path.value = "/CancelLeaveInfo";
      }

      router.push({
        path: path.value,
        query: {
          id: item.businessId,
          procId: item.processInstanceId,
          taskId: item.taskId,
          handleType: handleType,
          procStatus: item.workflowStatus,
          type: 0,
          typeLeave: checkIndex.value,
        },
      });
    };

    const approvalList = reactive({ data: new Array() });

    const resetPage = () => {
      searchParams.page = 1;
      editBoolen.value = false;
      selectAll.value = false;
      procVarslist.length = 0;
      procVars.length = 0;
      getListRequest((res: any) => {
        approvalList.data = res;
        showNoDate.value = res == null || res.length <= 0;
      });
    };
    const nextPage = (event: any) => {
      searchParams.page++;
      getListRequest((result: any) => {
        if (result) {
          approvalList.data = approvalList.data.concat(result);
        }
        event.target.complete();
      });
      setTimeout(() => {
        event.target.complete();
      }, 2000);
    };
    const doRefresh = (event: any) => {
      resetPage();
      setTimeout(() => {
        event.target.complete();
      }, 1000);
    };
    const getListRequest = (callback: any) => {
      let params = {
        page: searchParams.page,
        size: searchParams.size,
        type: checkIndex.value == 0 ? "" : checkIndex.value,
      };
      api
        .getTodoList(params)
        .then((res: any) => {
          for (let index = 0; index < res.length; index++) {
            Object.assign(res[index], { checked: false });
            selectAll.value = false;
          }
          callback(res);
        })
        .catch(() => {});
    };
    const editBoolen = ref(false);
    const selectAll = ref(true);
    const procVarslist = reactive(new Array());
    const procVars = reactive(new Array());
    const refuseReason = ref();
    //是否一键审批
    const isShowBtn = () => {
      editBoolen.value = !editBoolen.value;
      selectAll.value = false;
      procVarslist.length = 0;
      procVars.length = 0;
    };
    //选中
    const checkbox = (index: any, checked: any, id: String) => {
      let approvalObj = approvalList.data[index];
      if (checked == false) {
        procVarslist.push(approvalObj);
        if (procVarslist.length === approvalList.data.length) {
          selectAll.value = true;
        }
      } else {
        procVarslist.splice(index, 1);
        if (procVarslist.length !== approvalList.data.length) {
          selectAll.value = false;
        }
      }
    };
    //全选
    const checkAll = (ev: any) => {
      procVarslist.length = 0;
      if (ev == false) {
        selectAll.value = true;
      } else {
        selectAll.value = false;
      }
      if (selectAll.value) {
        approvalList.data.forEach((item) => {
          item.checked = true;
          procVarslist.push(item);
        });
      } else {
        approvalList.data.forEach((item) => {
          item.checked = false;
          procVarslist.length = 0;
        });
      }
    };
    //审批同意
    const agreeHandle = () => {
      if (procVarslist.length <= 0) {
        Utils.presentToastWarning("请您选择要审批的数据！");
        return;
      }
      Utils.confirmDialog("同意", "请确认是否批量审批", function () {
        submitHandle(1);
      });
    };
    //审批不同意
    const refuseHandle = () => {
      if (procVarslist.length <= 0) {
        Utils.presentToastWarning("请您选择要审批的数据！");
        return;
      }
      Utils.confirmDialog(
        "不同意",
        "<div><textarea style='height:50px;' class='alert-textarea' id='refuseReason' placeholder='请输入不同意原因' rows='8' maxlength='100' :required='true'></textarea></div>",
        function () {
          let textarea = document.getElementById(
            "refuseReason"
          ) as HTMLTextAreaElement;
          let val = textarea.value;
          if (val.replace(/\s+/g, "") == "") {
            Utils.presentToastWarning("请输入不同意原因");
            return;
          }
          if (regExps.SpecChar.test(val)) {
            Utils.presentToastWarning("不同意原因不能输入特殊字符哦！");
            return;
          }
          refuseReason.value = val;
          submitHandle(2);
        }
      );
    };

    const submitHandle = (type: any) => {
      let approveStatus = type == 1 ? true : false;
      procVarslist.forEach((item) => {
        procVars.push({ taskId: item.taskId, procId: item.processInstanceId });
      });
      let paramSend = {
        listReciveVar: [
          {
            key: "approve",
            value: approveStatus,
          },
        ],
        option: refuseReason.value,
        procVars: procVars,
      };
      context.emit("on-edit");
      api
        .sendBatch(paramSend)
        .then((res: any) => {
          Utils.presentToast("审批成功");
          resetPage();
        })
        .catch((err: any) => {
          resetPage();
          Utils.presentToastWarning(err.data.error);
        });
    };

    onMounted(() => { 
      resetPage();
    });

    return {
      checkIndex,
      checkChip,
      chips,
      approvalList,
      approvalInfo,
      nextPage,
      doRefresh,
      showNoDate,
      isShowBtn,
      editBoolen,
      procVarslist,
      checkbox,
      checkAll,
      agreeHandle,
      refuseHandle,
      selectAll,
      resetPage,
    };
  },
});
