
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import Location from "@/components/Location.vue";
import onDutySignService from "@/services/attendance/onDutySign/onDutySignService";
import Utils from "@/utils/Utils";
import {
  FileAttendanceHelper,
  UploadStatus,
  UploadMessage,
} from "@/utils/FileAttendanceHelper";

export default defineComponent({
  name: "OutSignIn",
  components: { Location },
  setup() {
    let location = ref("");
    let longitude = ref(0);
    let latitude = ref(0);
    let remark = ref("");
    let refreshTime = ref(0);
    const maxCount = ref(3);
    const attachments = reactive({
      data: new Array(),
    });

    const generateLocation = (r: any) => {
      if (r) {
        longitude.value = r.longitude;
        latitude.value = r.latitude;
        location.value = r.location;
      }
    };

    const refreshLocation = () => {
      refreshTime.value = new Date().getTime();
    };

    const service = new onDutySignService();

    const hasPermission = ref(false);

    const checkHasPermission = () => {
      service
        .hasPermission()
        .then(
          (r: any) => {
            if (r) {
              hasPermission.value = true;
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {
          Utils.presentToastWarning(c);
        });
    };

    const signInFiles = reactive({
      data: new Array<any>(),
      progress: "0",
    });

    const saveSignInfo = () => {
      if (latitude.value == 0 || longitude.value == 0) {
        Utils.presentToastWarning("无法获取定位信息");
        return;
      }
      let params = {
        address: location.value,
        latitude: latitude.value,
        longitude: longitude.value,
        descrip: remark.value,
        attachments: attachments.data,
      };
      service
        .onDutySign(params)
        .then(
          (r: any) => {
            Utils.presentToastSuccess("签到成功");
            latitude.value = 0;
            longitude.value = 0;
            location.value = "";
            remark.value = "";
            attachments.data = [];
            signInFiles.data = [];
            refreshLocation();
          },
          (e: any) => {
            let mes = "";
            if (e === "地址不能为空") {
              mes = "签到地点不能为空";
            } else {
              mes = e.data.error;
            }
            Utils.presentToastWarning(mes);
          }
        )
        .catch((c: any) => {
          Utils.presentToastWarning(c);
        });
    };

    const bindOverSize = (file: any) => {
      Utils.presentToastWarning("文件大小不能超过10MB");
    };

    const fileAttendanceHelper = new FileAttendanceHelper();

    //上传图片-自定义选择框
    const bindUploadPicture = async () => {
      //打开选择框
      fileAttendanceHelper.openFileSheet(
        (path: any) => {
          if (path) {
            let pic = {
              url: "",
              status: UploadStatus.Uploading,
              message: UploadMessage.Uploading,
              isImage: true,
            };
            signInFiles.data.push(pic);
            fileAttendanceHelper.uploadFile(path, service.getUploadFilePath()).then(
              (result: any) => {
                attachments.data.push(result.httpPath);
                const index: number = signInFiles.data.length - 1;
                signInFiles.data[index].url = result.httpPath;
                signInFiles.data[index].status = UploadStatus.Done;
                signInFiles.data[index].message = UploadMessage.Done;
              },
              (e: any) => {
                signInFiles.data.splice(signInFiles.data.length - 1, 1);
              }
            );
          }
        },
        (error: string) => {
          Utils.presentToastWarning(error);
        }
      );
    };

    //删除文件
    const bindDeletePicture = (ev: any) => {
      removeFile(ev.url);
    };

    const removeFile = (url: string) => {
      for (let index = 0; index < signInFiles.data.length; index++) {
        if (url === signInFiles.data[index].url) {
          signInFiles.data.splice(index, 1);
          attachments.data.splice(index, 1);
        }
      }
    };

    onMounted(() => {
      checkHasPermission();
    });

    return {
      generateLocation,
      refreshLocation,
      location,
      remark,
      refreshTime,
      attachments,
      saveSignInfo,
      signInFiles,
      bindOverSize,
      bindUploadPicture,
      bindDeletePicture,
      maxCount,
      checkHasPermission,
      hasPermission,
    };
  },
});
