import { PermissionApi } from '@/api/app/permission/PermissionApi';

export class PermissionService {
    private api = new PermissionApi();

    //未选择的功能列表
    public unselect(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.unselect().then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}