
import AppBaseService from '@/api/attendance/base/AppBaseService';

export class PermissionApi extends AppBaseService {
    constructor() {
        super("permission/my");
    }
    //未选择的功能列表
    public unselect():Promise<any>{
       return  this.request('unselect',this.rest.METHOD_GET)
    }
 
}