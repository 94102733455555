
import { defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "AttendanceStatisticsPage",
  setup() {
    const statsList = reactive([
      {
        src: require("@/assets/images/attendance/apply/leave.png"),
        title: "请假记录",
        path: "ApplyStatsPage",
      },
      {
        src: require("@/assets/images/attendance/apply/cancel.png"),
        title: "销假记录",
        path: "ApplyStatsPage",
      },
      {
        src: require("@/assets/images/attendance/apply/trip.png"),
        title: "出差记录",
        path: "ApplyStatsPage",
      },
      {
        src: require("@/assets/images/attendance/apply/out.png"),
        title: "外出记录",
        path: "ApplyStatsPage",
      },
      {
        src: require("@/assets/images/attendance/apply/place.png"),
        title: "驻场记录",
        path: "ApplyStatsPage",
      },
      // {
      //   src: require("@/assets/images/attendance/apply/repair.png"),
      //   title: "补假记录",
      //   path: "ApplyStatsPage",
      // },
      // {
      //   src: require("@/assets/images/attendance/apply/repair.png"),
      //   title: "考勤记录",
      //   path: "LeaveApplyPage",
      // },
      {
        src: require("@/assets/images/attendance/apply/repair.png"),
        title: "员工考勤统计",
        path: "attendancestatistics",
      },
    ]);
     const router = useRouter();
    const goInto = (item: any) => { 
        router.push({
          path: item.path,
          query: { title:item.title },
        });
    };
    return {
      statsList,
       goInto,
    };
  },
});
