
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AttendanceManagePage",
  props: {
    unSelectPermission:String,
  },
  data () {
    return {
      hasManageStationPer:false  
    }
  },
  mounted () {
    if(this.unSelectPermission){
      const jsonUnSelectPerm=JSON.parse(this.unSelectPermission);
      const _index=jsonUnSelectPerm.findIndex((o:any)=>{return o.code == "mobileAttendancesysStationStaff";});
      if(_index>=0){
        this.hasManageStationPer=true;
      }
    }
    if(!this.hasManageStationPer){
      this.manageList.data.splice(2,1);
    }
  },
  setup() {
    const manageList = reactive({
      data: [
        {
          src: require("@/assets/images/attendance/manage/MyAttendance.png"),
          title: "我的考勤",
          path: "MyAttendancePage",
        },
        {
          src: require("@/assets/images/attendance/manage/MyHoliday.png"),
          title: "我的假期",
          path: "MyHolidayPage",
        },
        {
          src: require("@/assets/images/attendance/manage/place.png"),
          title: "驻场管理",
          path: "/att/place",
        },
        {
          src: require("@/assets/images/attendance/manage/system.png"),
          title: "考勤制度",
          path: "Institution",
        },
      ],
    });

    const router = useRouter();
    const goInto = (path: string) => {
      router.push({
        path: path,
      });
    };
    return {
      manageList,
      goInto,
    };
  },
});
