
import { defineComponent, watch, ref, toRefs, onBeforeMount } from "vue";
import SigninService from "@/services/attendance/signin/SigninService";

export default defineComponent({
  name: "QrcodeSignIn",
  props: {
    userInfo: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup(props) {
    const { userInfo } = toRefs(props);
    let user: any;
    if (userInfo != undefined && userInfo.value != undefined) {
      user = userInfo.value;
    }
    let qrcodeUrl = ref("");
    const service = new SigninService();

    //请求获取二维码图片访问地址
    const loadQrCode = () => {
      const path = user.userCode + ":0";
      service
        .generateQRCode(path)
        .then(
          (r: any) => {
            if (r) {
              const code64 = "data:image/jpg;base64," + r.base64;
              qrcodeUrl.value = code64;
              localStorage.setItem("qrcode", code64);
              localStorage.setItem("usercode", user.userCode);
            }
          },
          (e: any) => {}
        )
        .catch((c: any) => {});
    };

    const initData = () => {
      const uc = localStorage.getItem("usercode");
      if (uc != null && uc == user.userCode) {
        const qc = localStorage.getItem("qrcode");
        if (qc != null) {
          qrcodeUrl.value = qc;
        } else {
          loadQrCode();
        }
      } else {
        loadQrCode();
      }
    };

    onBeforeMount(() => {
      if (user.userCode != undefined) {
        initData();
      }
    });

    watch(userInfo, (newCode, oldCode) => {
      user = newCode;
      initData();
    });

    return {
      qrcodeUrl,
    };
  },
});
