
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "AttendanceApplyPage",
  setup() {
    const applyList = reactive({
      data: [
        {      
          src: require("@/assets/images/attendance/apply/leave.png"),
          title: "请假申请",
          path: "LeaveApplyPage",
        },
        {
          src: require("@/assets/images/attendance/apply/cancel.png"),
          title: "销假申请",
          path: "CancelLeavePage",
        },
        {
          src:require("@/assets/images/attendance/apply/trip.png"),
          title: "出差申请",
          path: "TravelApply",
        },
        {
          src: require("@/assets/images/attendance/apply/out.png"),
          title: "外出申请",
          path: "OndutyApply",
        },
        {
          src:  require("@/assets/images/attendance/apply/place.png"),
          title: "驻场申请",
          path: "StationingApply",
        },
        { src:  require("@/assets/images/attendance/apply/unusual_clock.png"), title: "异常打卡申请", path:"MyAttendancePage" },
        {
          src:  require("@/assets/images/attendance/apply/repair.png"),
          title: "补假申请",
          path: "LeaveApplyPage",
        },
      ],
    });

    const router = useRouter();
    const goInto = (item: any) => {
      if (item.title == "补假申请") {
        router.push({
          path: item.path,
          query: { type: 1 },
        });
      } else {
        router.push({
          path: item.path,
        });
      }
    };

    return {
      applyList,
      goInto,
    };
  },
});
